<template>
  <v-row class="ma-0 pa-0">
    <v-col cols="12" class="pa-0 ma-0">
      <v-tabs
        v-model="tab"
        centered
        grow
      >
        <v-tab
          v-if="mostrarVista(18)"
          href="#empleados"
          @click="actualizarItems('empleados')"
        >
          Empleados
        </v-tab>
        <v-tab
          v-if="mostrarVista(29)"
          href="#empleados_validar"
          @click="actualizarItems('empleados_validar')"
        >
          Empleados por Validar Documentación
        </v-tab>
        <v-tab
          v-if="vinculado"
          href="#expediente"
          @click="actualizarItems('expediente')"
        >
          Mi Expediente
        </v-tab>
      </v-tabs>
          
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-if="mostrarVista(18)"
          value="empleados"
        >
          <empleados
            ref="empleados"
          ></empleados>
        </v-tab-item>

        <v-tab-item
          v-if="mostrarVista(29)"
          value="empleados_validar"
        >
          <empleados-validar
            ref="empleados_validar"
          ></empleados-validar>
        </v-tab-item>

        <v-tab-item
          v-if="vinculado"
          value="expediente"
        >
          <expediente
            ref="expediente"
          ></expediente>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import Empleados from './RecursosHumanos/Empleados.vue'
import EmpleadosValidar from './RecursosHumanos/EmpleadosValidar.vue'
import Expediente from './RecursosHumanos/Expediente.vue'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['ids_vistas', 'vinculado'])
  },
  components:{
    Empleados,
    EmpleadosValidar,
    Expediente
  },
  data: () => ({
    tab: 'empleados'
  }),
  methods: {
    mostrarVista(id_vista){
			if(this.ids_vistas == null || this.ids_vistas.length == 0) return false;
			return (this.ids_vistas.indexOf(id_vista) >= 0);
		},
    getItems(){
      this.actualizarItems(this.tab);
    },
    actualizarItems(refName) {
      if (this.$refs[refName] && this.$refs[refName].getItems) {
        this.$refs[refName].getItems();
      } else {
        console.warn(`No se encontró la referencia: ${refName}`);
      }
    },
    gotoExpediente(){
      this.tab = 'expediente';
    }
  }
}
</script>