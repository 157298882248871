<template>
  <v-row class="ma-0 pa-0">
    <v-col cols="12" class="pa-0 ma-0">
      <v-tabs
        v-model="tab"
        centered
        grow
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab
          v-if="mostrarVista(3)"
          href="#pacientes_dia"
          @click="actualizarItems('pacientes_dia')"
        >
          Pacientes del Día
        </v-tab>

        <v-tab
          v-if="mostrarVista(24)"
          href="#historico"
          @click="actualizarItems('historico')"
        >
          Historial de Pacientes
        </v-tab>
      </v-tabs>
          
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-if="mostrarVista(3)"
          value="pacientes_dia"
        >
          <PacientesDia
            ref="pacientes_dia"
          ></PacientesDia>
        </v-tab-item>

        <v-tab-item
          v-if="mostrarVista(24)"
          value="historico"
        >
          <Historico
            ref="historico"
          ></Historico>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import Historico from './Pacientes/Historico.vue'
import PacientesDia from './Pacientes/PacientesDia.vue'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['ids_vistas'])
  },
  components:{
    Historico,
    PacientesDia
  },
  data: () => ({
    tab: 'pacientes_dia'
  }),
  methods: {
    mostrarVista(id_vista){
      if(this.ids_vistas == null || this.ids_vistas.length == 0) return false;
      return (this.ids_vistas.indexOf(id_vista) >= 0);
    },
    getItems(){
      this.actualizarItems(this.tab);
    },
    actualizarItems(refName) {
      if (this.$refs[refName] && this.$refs[refName].getItems) {
        this.$refs[refName].getItems();
      } else {
        console.warn(`No se encontró la referencia: ${refName}`);
      }
    }
  }
}
</script>