<template>
  <v-container style="max-width: 1800px !important">
    <div class="pa-3 ma-3">
      <v-dialog
        v-model="dialogValidate"
        max-width="40%"
        persistent
      >
        <v-card>
          <v-card-title>Revisión de Proveedor</v-card-title>
          <v-card-text>
            <p>
              ¿Desea aceptar o rechazar al proveedor {{ editedItem.nombre_completo }}
              con Clave {{ editedItem.clave }}?
            </p>

            <v-row class="ma-0 pa-0">
              <v-col cols="12" class="text-center">
                <v-btn-toggle
                  v-model="validationItem.decision"
                  mandatory
                >
                  <v-btn text color="green" :value="1">Aceptar</v-btn>
                  <v-btn text color="red" :value="0">Rechazar</v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>

            <v-text-field
              v-if="validationItem.decision === 0"
              v-model="validationItem.motivoRechazo"
              label="Motivo de rechazo"
              required
              dense
              class="mt-3"
            ></v-text-field>
          </v-card-text>

          <v-card-actions>
            <v-btn
              text
              color="green"
              @click="closeDialogValidate"
            >
              Cancelar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="red"
              :disabled="validationItem.decision === 0 && !validationItem.motivoRechazo"
              @click="saveValidation"
            >
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialogDelete"
        max-width="65%"
        persistent
      >
        <v-card>
          <v-card-title>
            Desactivar Proveedor
          </v-card-title>
          <v-card-text>
            ¿Desea Desactivar el Proveedor {{editedItem.nombre_completo}} con Clave {{editedItem.clave}}?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="green"
              class="white--text"
              @click="closeDialogDelete"
            >
              Cancelar
            </v-btn>
            <v-btn
              text
              color="red"
              class="white--text"
              @click="closeDialogDelete"
            >
              Desactivar Proveedor
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialogView" max-width="90%"
      >
        <v-card>
          <v-card-title>
            Información del Proveedor
          </v-card-title>
          <v-card-text>
            <v-alert
              v-if="editedItem.motivo_bloqueo != null && editedItem.motivo_bloqueo.length > 0"
              type="error"
              color="red"
              dark
              class="text-center"
            >
              {{editedItem.motivo_bloqueo}} - {{editedItem.usuario_bloqueo}} desde {{editedItem.bloqueado_desde}}.
            </v-alert>
            <v-row>
              <v-col cols="12" class="text-center">
                <h3>Información Básica</h3>
              </v-col>

              <v-col cols="12" md="6">
                <div><strong>Tipo de Proveedor:</strong> {{ editedItem.tipo_proveedor }}</div>
              </v-col>
              <v-col cols="12" md="6">
                <div><strong>Tipo de Persona:</strong> {{ editedItem.tipo_persona }}</div>
              </v-col>

              <template v-if="editedItem.id_tipo_proveedor == '34a6560f-61c4-4bd0-a778-b7e641818fd0'">
                <v-col cols="12" md="6">
                  <div><strong>Tipo de Proveedor Nacional:</strong> {{ getTipoProveedorNacional(editedItem.id_tipo_proveedor_nacional) }}</div>
                </v-col>
              </template>

              <v-col cols="12" md="6">
                <div><strong>Nombre Corto del Proveedor:</strong> {{ editedItem.nombre_corto }}</div>
              </v-col>

              <v-col cols="12" md="6">
                <div><strong>Nombre Completo del Proveedor:</strong> {{ editedItem.nombre_completo }}</div>
              </v-col>

              <v-col cols="12" md="6">
                <div><strong>Nombre del Contacto:</strong> {{ editedItem.nombre_contacto }}</div>
              </v-col>

              <v-col cols="12" md="6">
                <div><strong>Teléfono de Contacto:</strong> {{ editedItem.telefono_contacto }}</div>
              </v-col>

              <v-col cols="12" md="6">
                <div><strong>Correo Electrónico:</strong> {{ editedItem.correo_electronico }}</div>
              </v-col>
            </v-row>

            <v-row justify="center">
              <v-expansion-panels
                v-model="panelsView"
                accordion
                multiple
              >
                <!-- Datos Geográficos -->
                <v-expansion-panel>
                  <v-expansion-panel-header class="text-center grey--text text--darken-2">
                    <h4>Datos Geográficos</h4>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="12">
                        <div><strong>URL de la ubicación en Google Maps:</strong> {{ editedItem.direccion_google_maps }}</div>
                      </v-col>
                      <v-col cols="6">
                        <v-viewer
                          v-model="editedItem.fachada_empresa_1"
                          urlBase="/api/Proveedores/DescargarDocumento"
                          label="Fotografía de la fachada de la empresa 1"
                        ></v-viewer>
                      </v-col>
                      <v-col cols="6">
                        <v-viewer
                          v-model="editedItem.fachada_empresa_2"
                          urlBase="/api/Proveedores/DescargarDocumento"
                          label="Fotografía de la fachada de la empresa 2"
                        ></v-viewer>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- Datos de la Sucursal -->
                <v-expansion-panel
                  v-if="
                    editedItem.calle_sucursal != null ||
                    editedItem.num_ext_sucursal != null ||
                    editedItem.num_int_sucursal != null ||
                    editedItem.estado_nombre != null ||
                    editedItem.municipio_nombre != null ||
                    editedItem.colonia_sucursal != null ||
                    editedItem.localidad_sucursal != null ||
                    editedItem.codigo_postal_sucursal != null ||
                    editedItem.ciudad_sucursal != null ||
                    editedItem.region_sucursal != null ||
                    editedItem.pais_nombre != null
                  "
                >
                  <v-expansion-panel-header class="text-center grey--text text--darken-2">
                    <div>
                      <h4>Datos de la Sucursal</h4>
                      <br>
                      (Si aplica)
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <template v-if="editedItem.id_tipo_proveedor == '34a6560f-61c4-4bd0-a778-b7e641818fd0'">
                        <v-col cols="6">
                          <div><strong>Calle:</strong> {{ editedItem.calle_sucursal }}</div>
                        </v-col>
                        <v-col cols="3">
                          <div><strong>Núm Exterior:</strong> {{ editedItem.num_ext_sucursal }}</div>
                        </v-col>
                        <v-col cols="3">
                          <div><strong>Núm Interior:</strong> {{ editedItem.num_int_sucursal }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Estado:</strong> {{ editedItem.estado_sucursal }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Municipio:</strong> {{ editedItem.municipio_sucursal }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Localidad:</strong> {{ editedItem.localidad_sucursal }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Colonia:</strong> {{ editedItem.colonia_sucursal }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Código Postal:</strong> {{ editedItem.codigo_postal_sucursal }}</div>
                        </v-col>
                      </template>
                      <template v-if="editedItem.id_tipo_proveedor == '4ae22cb8-3564-41e9-a726-302cb6762e02'">
                        <v-col cols="6">
                          <div><strong>Calle:</strong> {{ editedItem.calle_sucursal }}</div>
                        </v-col>
                        <v-col cols="3">
                          <div><strong>Núm Exterior:</strong> {{ editedItem.num_ext_sucursal }}</div>
                        </v-col>
                        <v-col cols="3">
                          <div><strong>Núm Interior:</strong> {{ editedItem.num_int_sucursal }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Código Postal:</strong> {{ editedItem.codigo_postal_sucursal }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Ciudad:</strong> {{ editedItem.ciudad_sucursal }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Región/Estado/Provincia:</strong> {{ editedItem.region_sucursal }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>País:</strong> {{ editedItem.pais_nombre }}</div>
                        </v-col>
                      </template>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel
                  v-if="editedItem.datos_fiscales != null && (
                    editedItem.datos_fiscales.nombre_regimen_fiscal ||
                    editedItem.datos_fiscales.razon_social ||
                    editedItem.datos_fiscales.rfc ||
                    editedItem.datos_fiscales.calle ||
                    editedItem.datos_fiscales.num_ext ||
                    editedItem.datos_fiscales.num_int ||
                    editedItem.datos_fiscales.nombre_estado ||
                    editedItem.datos_fiscales.nombre_municipio ||
                    editedItem.datos_fiscales.colonia ||
                    editedItem.datos_fiscales.codigo_postal ||
                    editedItem.datos_fiscales.numero_fiscal ||
                    editedItem.datos_fiscales.ciudad ||
                    editedItem.datos_fiscales.region ||
                    editedItem.datos_fiscales.nombre_pais
                  )"
                >
                  <v-expansion-panel-header class="text-center grey--text text--darken-2">
                    <h4>Datos Fiscales</h4>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <!-- Datos Fiscales Nacionales -->
                      <template v-if="editedItem.id_tipo_proveedor == '34a6560f-61c4-4bd0-a778-b7e641818fd0'">
                        <v-col cols="6">
                          <div><strong>Régimen Fiscal:</strong> {{ editedItem.datos_fiscales.nombre_regimen_fiscal }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Nombre/Razón Social:</strong> {{ editedItem.datos_fiscales.razon_social }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div :class="(editedItem.motivo_bloqueo != null && editedItem.motivo_bloqueo.length > 0) ? 'red--text' : ''"><strong>RFC:</strong> {{ editedItem.datos_fiscales.rfc }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Calle:</strong> {{ editedItem.datos_fiscales.calle }}</div>
                        </v-col>
                        <v-col cols="3">
                          <div><strong>Núm Exterior:</strong> {{ editedItem.datos_fiscales.num_ext }}</div>
                        </v-col>
                        <v-col cols="3">
                          <div><strong>Núm Interior:</strong> {{ editedItem.datos_fiscales.num_int }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Estado:</strong> {{ editedItem.datos_fiscales.nombre_estado }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Municipio:</strong> {{ editedItem.datos_fiscales.nombre_municipio }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Localidad:</strong> {{ editedItem.datos_fiscales.localidad }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Colonia:</strong> {{ editedItem.datos_fiscales.colonia }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Código Postal:</strong> {{ editedItem.datos_fiscales.codigo_postal }}</div>
                        </v-col>
                      </template>

                      <!-- Datos Fiscales Internacionales -->
                      <template v-if="editedItem.id_tipo_proveedor == '4ae22cb8-3564-41e9-a726-302cb6762e02'">
                        <v-col cols="6">
                          <div><strong>Nombre/Razón Social:</strong> {{ editedItem.datos_fiscales.razon_social }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Número de Identificación Fiscal:</strong> {{ editedItem.datos_fiscales.numero_fiscal }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Calle:</strong> {{ editedItem.datos_fiscales.calle }}</div>
                        </v-col>
                        <v-col cols="3">
                          <div><strong>Núm Exterior:</strong> {{ editedItem.datos_fiscales.num_ext }}</div>
                        </v-col>
                        <v-col cols="3">
                          <div><strong>Núm Interior:</strong> {{ editedItem.datos_fiscales.num_int }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Código Postal:</strong> {{ editedItem.datos_fiscales.codigo_postal }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Ciudad:</strong> {{ editedItem.datos_fiscales.ciudad }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Región/Estado/Provincia:</strong> {{ editedItem.datos_fiscales.region }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>País:</strong> {{ editedItem.datos_fiscales.nombre_pais }}</div>
                        </v-col>
                      </template>

                      <!-- Teléfono -->
                      <v-col cols="6">
                        <div><strong>Teléfono Empresarial/Sucursal:</strong> {{ editedItem.datos_fiscales.telefono }}</div>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header class="text-center grey--text text--darken-2">
                    <h4>Cuentas Bancarias</h4>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div class="text-center d-flex text-subtitle-2" style="align-items: center !important;">
                      <v-spacer/>
                      <v-img src="/img/mexico.png" aspect-ratio="1.0" max-width="48px"></v-img>
                      &nbsp;&nbsp;
                      Cuentas Bancarias Nacionales
                      <v-spacer/>                        
                    </div>
                    <nacionales
                      :enableAddUpdate="false"
                      ref="nacionales_view"
                      :id_proveedor="editedItem.id_proveedor"
                    ></nacionales>
                    <div class="text-center d-flex text-subtitle-2" style="align-items: center !important;">
                      <v-spacer/>
                      <v-img src="/img/planeta.png" aspect-ratio="1.0" max-width="48px"></v-img>
                      &nbsp;&nbsp;
                      Cuentas Bancarias Internacionales
                      <v-spacer/>                        
                    </div>
                    <internacionales
                      :enableAddUpdate="false"
                      ref="internacionales_view"
                      :id_proveedor="editedItem.id_proveedor"
                    ></internacionales>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- Documentos Adjuntos -->
                <v-expansion-panel
                  v-if="editedItem.curriculum_vitae_empresarial ||
                    editedItem.situacion_fiscal ||
                    editedItem.opinion_cumplimiento ||
                    editedItem.caratula_estado_cuenta ||
                    editedItem.certificado_1 ||
                    editedItem.certificado_2 ||
                    editedItem.certificado_3 ||
                    editedItem.identificacion_oficial ||
                    editedItem.comprobante_domicilio ||
                    editedItem.referencia_comercial_1 ||
                    editedItem.referencia_comercial_2 ||
                    editedItem.certificacion ||
                    editedItem.fotografia_producto_1 ||
                    editedItem.fotografia_producto_2 ||
                    editedItem.fotografia_producto_3 ||
                    editedItem.fotografia_producto_4 ||
                    editedItem.curriculum_empresa ||
                    editedItem.acta_constitutiva ||
                    editedItem.poder_representante ||
                    editedItem.identificacion_representante ||
                    editedItem.cedula_identificacion_fiscal ||
                    editedItem.curp ||
                    editedItem.solicitud_certificacion ||
                    editedItem.cedula_fiscal_moral ||
                    editedItem.testimonio_escritura ||
                    editedItem.solicitud_certificacion_morales ||
                    editedItem.curp_representante ||
                    editedItem.poliza_responsabilidad_civil_medica ||
                    editedItem.copia_titulo_medico ||
                    editedItem.cedula_profesional_medico ||
                    editedItem.diploma_especialidad ||
                    editedItem.cedula_radiologia"

                >
                  <v-expansion-panel-header class="text-center grey--text text--darken-2">
                    <h4>Documentos Adjuntos</h4>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list dense>
                      <!-- Documentos para Proveedor Nacional / Persona Física -->
                      <template v-if="editedItem.id_tipo_proveedor === '34a6560f-61c4-4bd0-a778-b7e641818fd0' && editedItem.id_tipo_persona === 'abddb0b2-32b4-47d4-8a75-7ac30e95c69b'">
                        <v-list-item v-if="editedItem.curriculum_vitae_empresarial" @click="downloadFile(editedItem.curriculum_vitae_empresarial)">
                          <v-list-item-content><strong>Currículum Vitae empresarial</strong></v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.situacion_fiscal" @click="downloadFile(editedItem.situacion_fiscal)">
                          <v-list-item-content><strong>Constancia de situación fiscal</strong></v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.opinion_cumplimiento" @click="downloadFile(editedItem.opinion_cumplimiento)">
                          <v-list-item-content><strong>Opinión de cumplimiento positiva de obligaciones fiscales</strong></v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.caratula_estado_cuenta" @click="downloadFile(editedItem.caratula_estado_cuenta)">
                          <v-list-item-content><strong>Copia de la carátula del estado de cuenta</strong></v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.certificado_1" @click="downloadFile(editedItem.certificado_1)">
                          <v-list-item-content><strong>Certificado 1</strong></v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.certificado_2" @click="downloadFile(editedItem.certificado_2)">
                          <v-list-item-content><strong>Certificado 2</strong></v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.certificado_3" @click="downloadFile(editedItem.certificado_3)">
                          <v-list-item-content><strong>Certificado 3</strong></v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.identificacion_oficial" @click="downloadFile(editedItem.identificacion_oficial)">
                          <v-list-item-content><strong>Copia simple de identificación oficial</strong></v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.comprobante_domicilio" @click="downloadFile(editedItem.comprobante_domicilio)">
                          <v-list-item-content><strong>Copia simple de comprobante domicilio</strong></v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.referencia_comercial_1" @click="downloadFile(editedItem.referencia_comercial_1)">
                          <v-list-item-content><strong>Carta de referencia comercial 1</strong></v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.referencia_comercial_2" @click="downloadFile(editedItem.referencia_comercial_2)">
                          <v-list-item-content><strong>Carta de referencia comercial 2</strong></v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.certificacion" @click="downloadFile(editedItem.certificacion)">
                          <v-list-item-content><strong>Copia de la certificación, licencia o permiso</strong></v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.fotografia_producto_1" @click="downloadFile(editedItem.fotografia_producto_1)">
                          <v-list-item-content><strong>Fotografía del producto o servicio 1</strong></v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.fotografia_producto_2" @click="downloadFile(editedItem.fotografia_producto_2)">
                          <v-list-item-content><strong>Fotografía del producto o servicio 2</strong></v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.fotografia_producto_3" @click="downloadFile(editedItem.fotografia_producto_3)">
                          <v-list-item-content><strong>Fotografía del producto o servicio 3</strong></v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.fotografia_producto_4" @click="downloadFile(editedItem.fotografia_producto_4)">
                          <v-list-item-content><strong>Fotografía del producto o servicio 4</strong></v-list-item-content>
                        </v-list-item>
                        <template v-if="editedItem.id_tipo_proveedor_nacional == 2">
                          <v-list-item v-if="editedItem.poliza_responsabilidad_civil_medica" @click="downloadFile(editedItem.poliza_responsabilidad_civil_medica)">
                            <v-list-item-content><strong>Póliza de responsabilidad civil medica</strong></v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="editedItem.copia_titulo_medico" @click="downloadFile(editedItem.copia_titulo_medico)">
                            <v-list-item-content><strong>Copia de título de Medico</strong></v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="editedItem.cedula_profesional_medico" @click="downloadFile(editedItem.cedula_profesional_medico)">
                            <v-list-item-content><strong>Copia de Cedula profesional como Medico</strong></v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="editedItem.diploma_especialidad" @click="downloadFile(editedItem.diploma_especialidad)">
                            <v-list-item-content><strong>Copia del diploma e especialidad</strong></v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="editedItem.cedula_radiologia" @click="downloadFile(editedItem.cedula_radiologia)">
                            <v-list-item-content><strong>Copia de la cedula de radiología</strong></v-list-item-content>
                          </v-list-item>
                        </template>
                      </template>

                      <!-- Documentos para Proveedor Nacional / Persona Moral -->
                      <template v-else-if="editedItem.id_tipo_proveedor === '34a6560f-61c4-4bd0-a778-b7e641818fd0' && editedItem.id_tipo_persona === '689a6d20-3aff-4a09-b5b4-52105ad212f0'">
                        <v-list-item v-if="editedItem.curriculum_empresa">
                          <v-list-item-content class="text-center">
                            <v-viewer
                              v-model="editedItem.curriculum_empresa"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Currículum de la empresa"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.acta_constitutiva">
                          <v-list-item-content>
                              <v-viewer
                                v-model="editedItem.acta_constitutiva"
                                urlBase="/api/Proveedores/DescargarDocumento"
                                label="Copia simple del acta constitutiva y actas modificatorias"
                              ></v-viewer>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.poder_representante">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.poder_representante"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Copia simple del poder notariado del representante legal"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.caratula_estado_cuenta">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.caratula_estado_cuenta"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Copia de la carátula del estado de cuenta"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.situacion_fiscal">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.situacion_fiscal"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Constancia de situación fiscal"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.opinion_cumplimiento">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.opinion_cumplimiento"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Opinión de cumplimiento positiva de obligaciones fiscales"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.certificado_1">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.certificado_1"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Certificado 1"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.certificado_2">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.certificado_2"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Certificado 2"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.certificado_3">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.certificado_3"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Certificado 3"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.identificacion_representante">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.identificacion_representante"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Copia simple de identificación oficial del representante legal"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="editedItem.certificacion">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.certificacion"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Copia de la certificación, licencia o permiso"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="editedItem.comprobante_domicilio">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.comprobante_domicilio"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Copia simple de comprobante domicilio"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="editedItem.referencia_comercial_1">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.referencia_comercial_1"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Carta de referencia comercial 1"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="editedItem.referencia_comercial_2">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.referencia_comercial_2"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Carta de referencia comercial 2"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>

                        <template v-if="editedItem.id_tipo_proveedor_nacional == 2">
                          <v-list-item v-if="editedItem.poliza_responsabilidad_civil_medica" @click="downloadFile(editedItem.poliza_responsabilidad_civil_medica)">
                            <v-list-item-content><strong>Póliza de responsabilidad civil medica</strong></v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="editedItem.copia_titulo_medico" @click="downloadFile(editedItem.copia_titulo_medico)">
                            <v-list-item-content><strong>Copia de título de Medico</strong></v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="editedItem.cedula_profesional_medico" @click="downloadFile(editedItem.cedula_profesional_medico)">
                            <v-list-item-content><strong>Copia de Cedula profesional como Medico</strong></v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="editedItem.diploma_especialidad" @click="downloadFile(editedItem.diploma_especialidad)">
                            <v-list-item-content><strong>Copia del diploma e especialidad</strong></v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="editedItem.cedula_radiologia" @click="downloadFile(editedItem.cedula_radiologia)">
                            <v-list-item-content><strong>Copia de la cedula de radiología</strong></v-list-item-content>
                          </v-list-item>
                        </template>
                      </template>

                      <!-- Documentos para Proveedor Internacional / Persona Física -->
                      <template v-else-if="editedItem.id_tipo_proveedor === '4ae22cb8-3564-41e9-a726-302cb6762e02' && editedItem.id_tipo_persona === 'abddb0b2-32b4-47d4-8a75-7ac30e95c69b'">
                        <v-list-item v-if="editedItem.cedula_identificacion_fiscal">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.cedula_identificacion_fiscal"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Cédula de identificación fiscal"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.caratula_estado_cuenta">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.caratula_estado_cuenta"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Copia de la carátula del estado de cuenta"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.identificacion_oficial">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.identificacion_oficial"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Identificación oficial con fotografía del país de origen"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.curp">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.curp"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Clave única de registro de población"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.solicitud_certificacion">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.solicitud_certificacion"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Solicitud de certificación del medio de identificación electrónica para personas físicas extranjeras"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>
                      </template>

                      <!-- Documentos para Proveedor Internacional / Persona Moral -->
                      <template v-else-if="editedItem.id_tipo_proveedor === '4ae22cb8-3564-41e9-a726-302cb6762e02' && editedItem.id_tipo_persona === '689a6d20-3aff-4a09-b5b4-52105ad212f0'">
                        <v-list-item v-if="editedItem.cedula_fiscal_moral">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.cedula_fiscal_moral"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Cédula de identificación fiscal de la persona moral"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="editedItem.testimonio_escritura">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.testimonio_escritura"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Testimonio de la escritura pública"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="editedItem.caratula_estado_cuenta">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.caratula_estado_cuenta"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Copia de la carátula del estado de cuenta"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="editedItem.solicitud_certificacion_morales">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.solicitud_certificacion_morales"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Solicitud de certificación del medio de identificación electrónica para personas morales extranjeras"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="editedItem.curp_representante">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.curp_representante"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Clave única de registro de población (CURP) del representante legal o apoderado"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="editedItem.identificacion_representante">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.identificacion_representante"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Identificación oficial con fotografía del representante legal"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="closeDialogView"
              color="green"
              text
            >
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-row class="ma-0 pa-0">
        <v-col cols="12" class="text-right ma-0 pa-0">
          <v-btn text color="primary" @click="nuevaPestana(`/api/Pagos/DescargarProveedores?jwt=${token}`)">
            <v-icon>
              mdi-download
            </v-icon>
            Descargar Catálogo Proveedores
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="ma-0 pa-0">
        <v-col cols="4">
          <v-autocomplete
            v-model="search.id_estado_parcial"
            item-value="id"
            item-text="nombre"
            :items="estadosProveedor"
            label="Estado"
            dense
            outlined
            hide-details
            clearable
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="search.email"
            label="Correo Electrónico"
            dense
            outlined
            hide-details
            clearable
          >
          </v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="search.rfc"
            label="RFC"
            dense
            outlined
            hide-details
            clearable
          >
          </v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="search.num_proveedor"
            label="# Proveedor"
            dense
            outlined
            hide-details
            clearable
          >
          </v-text-field>
        </v-col>
        <!--
        <v-col cols="4">
          <v-autocomplete
            v-model="search.id_estatus"
            item-value="id"
            item-text="nombre"
            :items="statusProveedor"
            label="Activo"
            dense
            outlined
            hide-details
            clearable
          >
          </v-autocomplete>
        </v-col>
        -->
        <v-col cols="4">
        </v-col>
        <v-col cols="4">
          <v-btn block color="primary" text outlined @click="getItems">
            <v-icon>mdi-magnify</v-icon>
            Buscar
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="items"
            :item-class="getRowClass"
          >
           
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                class="mr-2"
                small
                :color="(item.motivo_bloqueo != null && item.motivo_bloqueo.length > 0) ? 'white' : ''"
                @click="openDialogView(item)"
              >
                  mdi-eye
              </v-icon>

              <v-icon
                v-if="item.id_estado_parcial == 1"
                small
                class="mr-2"
                @click="openDialogValidate(item)"
              >
                mdi-check
              </v-icon>
              <!--
              <v-icon
                v-if="!(item.motivo_bloqueo != null && item.motivo_bloqueo.length > 0)"
                class="mr-2"
                small
                @click="openDialogDelete"
              >
                mdi-delete
              </v-icon>
              -->
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import Nacionales from './Cuentas/Nacionales.vue'
import Internacionales from './Cuentas/Internacionales.vue'
import VViewer from '@/components/VViewer.vue';
import { mapState } from 'vuex'

export default {
  components: {
    Nacionales,
    Internacionales,
    VViewer
  },
  data: () => ({
    loading: false,
    items: [],
    estadosProveedor: [
      { id: 4, nombre:'Bloqueado'},
      { id: 1, nombre:'Pendiente'},
      { id: 2, nombre:'Aceptado'},
      { id: 3, nombre:'Rechazado'},
    ],
    statusProveedor: [
      { id: 1, nombre:'Si'},
      { id: 2, nombre:'No'},
    ],
    headers: [
      {
        text: "Estado",
        value: "estado_parcial",
        align: "center",
      },
      {
        text: "Clave del Proveedor",
        value: "clave",
        align: "center",
      },
      {
        text: "Nombre Corto del Proveedor",
        value: "nombre_corto",
        align: "center",
      },
      {
        text: "Nombre Completo del Proveedor",
        value: "nombre_completo",
        align: "center",
      },
      {
        text: "Tipo de Persona",
        value: "tipo_persona",
        align: "center",
      },

      {
        text: "Tipo de Proveedor",
        value: "tipo_proveedor",
        align: "center",
      },
      {
        text: "Nombre del Contacto",
        value: "nombre_contacto",
        align: "center",
      },

      {
        text: "Teléfono de Contacto",
        value: "telefono_contacto",
        align: "center",
      },

      {
        text: "Correo Electrónico",
        value: "correo_electronico",
        align: "center",
      },

      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        align: "center",
      },
    ],
    editedItem: {
      id_proveedor: null,
      clave: null,
      id_tipo_persona: null,
      tipo_persona: null,
      id_tipo_proveedor: null,
      tipo_proveedor: null,
      nombre_corto: null,
      nombre_completo: null,
      nombre_contacto: null,
      telefono_contacto: null,
      correo_electronico: null,
      id_tipo_proveedor_nacional: null,
      direccion_google_maps: null,
      calle_sucursal: null,
      num_ext_sucursal: null,
      num_int_sucursal: null,
      id_estado_sucursal: null,
      id_municipio_sucursal: null,
      localidad_sucursal: null,
      colonia_sucursal: null,
      codigo_postal_sucursal: null,
      ciudad_sucursal: null,
      region_sucursal: null,
      pais_sucursal: null,
      curriculum_vitae_empresarial: null,
      identificacion_oficial: null,
      comprobante_domicilio: null,
      caratula_estado_cuenta: null,
      situacion_fiscal: null,
      opinion_cumplimiento: null,
      referencia_comercial_1: null,
      referencia_comercial_2: null,
      certificacion: null,
      fotografia_producto_1: null,
      fotografia_producto_2: null,
      fotografia_producto_3: null,
      fotografia_producto_4: null,
      curriculum_empresa: null,
      acta_constitutiva: null,
      poder_representante: null,
      identificacion_representante: null,
      fachada_empresa_1: null,
      fachada_empresa_2: null,
      cedula_identificacion_fiscal: null,
      curp: null,
      solicitud_certificacion: null,
      solicitud_certificacion_morales: null,
      curp_representante: null,
      cedula_fiscal_moral: null,
      testimonio_escritura: null,
      certificado_1: null,
      certificado_2: null,
      certificado_3: null,
      poliza_responsabilidad_civil_medica: null,
      copia_titulo_medico: null,
      cedula_profesional_medico: null,
      diploma_especialidad: null,
      cedula_radiologia: null,
      nivel_proveedor: null,
      datos_fiscales: {
        bloquear_rfc: false,
        calle: null,
        num_ext: null,
        num_int: null,
        razon_social: null,
        rfc: null,
        id_estado: null,
        id_municipio: null,
        id_regimen_fiscal: null,
        codigo_postal: null,
        localidad: null,
        colonia: null,
        numero_fiscal: null,
        ciudad: null,
        region: null,
        id_pais: null,
        telefono: null,
      }
    },
    defaultEditedItem: {
      id_proveedor: null,
      clave: null,
      id_tipo_persona: null,
      tipo_persona: null,
      id_tipo_proveedor: null,
      tipo_proveedor: null,
      nombre_corto: null,
      nombre_completo: null,
      nombre_contacto: null,
      telefono_contacto: null,
      correo_electronico: null,
      id_tipo_proveedor_nacional: null,
      direccion_google_maps: null,
      calle_sucursal: null,
      num_ext_sucursal: null,
      num_int_sucursal: null,
      id_estado_sucursal: null,
      id_municipio_sucursal: null,
      localidad_sucursal: null,
      colonia_sucursal: null,
      codigo_postal_sucursal: null,
      ciudad_sucursal: null,
      region_sucursal: null,
      pais_sucursal: null,
      curriculum_vitae_empresarial: null,
      identificacion_oficial: null,
      comprobante_domicilio: null,
      caratula_estado_cuenta: null,
      situacion_fiscal: null,
      opinion_cumplimiento: null,
      referencia_comercial_1: null,
      referencia_comercial_2: null,
      certificacion: null,
      fotografia_producto_1: null,
      fotografia_producto_2: null,
      fotografia_producto_3: null,
      fotografia_producto_4: null,
      curriculum_empresa: null,
      acta_constitutiva: null,
      poder_representante: null,
      identificacion_representante: null,
      fachada_empresa_1: null,
      fachada_empresa_2: null,
      cedula_identificacion_fiscal: null,
      curp: null,
      solicitud_certificacion: null,
      solicitud_certificacion_morales: null,
      curp_representante: null,
      cedula_fiscal_moral: null,
      testimonio_escritura: null,
      certificado_1: null,
      certificado_2: null,
      certificado_3: null,
      poliza_responsabilidad_civil_medica: null,
      copia_titulo_medico: null,
      cedula_profesional_medico: null,
      diploma_especialidad: null,
      cedula_radiologia: null,
      nivel_proveedor: null,
      datos_fiscales: {
        bloquear_rfc: false,
        calle: null,
        num_ext: null,
        num_int: null,
        razon_social: null,
        rfc: null,
        id_estado: null,
        id_municipio: null,
        id_regimen_fiscal: null,
        codigo_postal: null,
        localidad: null,
        colonia: null,
        numero_fiscal: null,
        ciudad: null,
        region: null,
        id_pais: null,
        telefono: null,
      }
    },
    dialogValidate: false,
    dialogDelete: false,
    dialogView: false,
    editedItemFiscal: {
      bloquear_rfc: false,
      calle: null,
      num_ext: null,
      num_int: null,
      razon_social: null,
      rfc: null,
      id_estado: null,
      id_municipio: null,
      id_regimen_fiscal: null,
      codigo_postal: null,
      localidad: null,
      colonia: null,
      numero_fiscal: null,
      ciudad: null,
      region: null,
      id_pais: null,
      telefono: null,
    },
    defaultEditedItemFiscal: {
      bloquear_rfc: false,
      calle: null,
      num_ext: null,
      num_int: null,
      razon_social: null,
      rfc: null,
      id_estado: null,
      id_municipio: null,
      id_regimen_fiscal: null,
      codigo_postal: null,
      localidad: null,
      colonia: null,
      numero_fiscal: null,
      ciudad: null,
      region: null,
      id_pais: null,
      telefono: null,
    },
    panelsView: [],
    tiposProveedorNacional: [
      {
        id_tipo_proveedor_nacional: 1,
        nombre: "Venta de Bienes y Servicios"
      },
      {
        id_tipo_proveedor_nacional: 2,
        nombre: "Servicio Profesional en Area Médica"
      },
      {
        id_tipo_proveedor_nacional: 3,
        nombre: "Servicios profesionales con acreditacion"
      }
    ],
    search: {
      id_estado_parcial: null,
      email: null,
      rfc: null,
      num_proveedor: null,
      id_estatus: null,
    },
    validationItem: {
      decision: null,
      motivoRechazo: null
    },
    defaultValidationItem: {
      decision: null,
      motivoRechazo: null
    },
    saving: false,
  }),
  mounted() {
    this.getItems();
  },
  computed: {
    ...mapState(['token'])
  },
  methods: {
    nuevaPestana(url) {
      window.open(url, '_blank');
    },
    getItems() {
      const url = new URL(`http://localhost/Pagos/ListarProveedoresParciales`);
      
      if(this.search.id_estado_parcial != null && this.search.id_estado_parcial >= 0) url.searchParams.append("id_estado_parcial", this.search.id_estado_parcial);
      if(this.search.email != null && this.search.email.length > 0) url.searchParams.append("email", this.search.email);
      if(this.search.rfc != null && this.search.rfc.length > 0) url.searchParams.append("rfc", this.search.rfc);
      if(this.search.num_proveedor != null && this.search.num_proveedor.length > 0) url.searchParams.append("num_proveedor", this.search.num_proveedor);
      if(this.search.id_estatus != null && this.search.id_estatus >= 0) url.searchParams.append("id_estatus", this.search.id_estatus);

      var url_string = url.href.replace('http://localhost', '');

      this.loading = true;
      axios
        .get(url_string)
        .then((response) => {
          this.items = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openDialogValidate(editItem){
      this.editedItem = Object.assign({}, editItem);
      this.dialogValidate = true;
      this.validationItem = Object.assign({}, this.defaultValidationItem);
    },
    closeDialogValidate(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogValidate = false;
      this.validationItem = Object.assign({}, this.defaultValidationItem);
    },
    saveValidation(){
      axios
      .put(`/Proveedores/ValidarProveedorParcial`, {
        id_proveedor: this.editedItem.id_proveedor,
        decision: this.validationItem.decision,
        motivo_rechazo: this.validationItem.motivoRechazo
      })
      .then(() => {
        this.closeDialogValidate();
        this.getItems();
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.saving = false;
      })
    },
    openDialogDelete(editItem){
      this.editedItem = Object.assign({}, editItem);
      this.dialogDelete = true;
    },
    closeDialogDelete(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogDelete = false;
    },
    getMunicipios(){
      if(this.editedItemFiscal.id_estado == null) return;
      this.loadingMunicipios = true;
      axios
        .get(`/Catalogos/ListarMunicipios/${this.editedItemFiscal.id_estado}`)
        .then(response => {
          this.municipios = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingMunicipios = false;
        })
    },
    openDialogView(editItem = null) {
      this.panelsView = []; 
      if (editItem != null) {
        this.editedItem = Object.assign({}, editItem);
        if(editItem.datos_fiscales != null){
          this.editedItemFiscal = editItem.datos_fiscales;
          this.getMunicipios();
        }
        else{
          this.editedItemFiscal = Object.assign({}, this.editedItemFiscal);
          this.editedItem.datos_fiscales = Object.assign({}, this.editedItemFiscal);
        }

        setTimeout(() => {
          if (this.$refs.nacionales_view && this.$refs.nacionales_view.load) this.$refs.nacionales_view.load();
          if (this.$refs.internacionales_view && this.$refs.internacionales_view.load) this.$refs.internacionales_view.load();
        }, 500);
      }
      else this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogView = true;
    },
    closeDialogView() {
      this.panelsView = []; 
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.editedItemFiscal = Object.assign({}, this.defaultEditedItemFiscal);
      this.dialogView = false;
    },
    getTipoProveedorNacional(id_tipo_proveedor_nacional){
      var idx = this.tiposProveedorNacional.findIndex(x => x.id_tipo_proveedor_nacional == id_tipo_proveedor_nacional);
      if(idx < 0) return "";
      else return this.tiposProveedorNacional[idx].nombre;
    },
    getRowClass(item) {
      return (item.motivo_bloqueo != null && item.motivo_bloqueo.length > 0) ? 'red-row' : '';
    },
  },
};
</script>