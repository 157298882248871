<template>
  <div>
    <v-alert-dialog
      ref="alert"
    >
    </v-alert-dialog>
    <v-dialog
      max-width="90%"
      v-model="dialogExpediente"
      persistent
    >
      <v-card>
        <v-card-title>
          <!--
          Expediente de {{paciente.nombre}} {{paciente.apellido_materno}} {{paciente.apellido_paterno}}
          -->
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="2">
              <v-card>
                <v-card-text>
                  <v-img
                    height="200"
                    :src="(paciente.fotografia != null) ? `/api/archivos/pacientes/${paciente.fotografia}?jwt=${token}` : '/img/persona.jpg'"
                  ></v-img>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="10">
              <v-card>
                <v-card-text>
                  <v-row class="ma-0 pa-0">
                    <v-col cols="6">
                      <span class="font-weight-bold">Folio Paciente:</span> {{paciente.folio}}
                      <br>
                      <span class="font-weight-bold">Nombre:</span> {{paciente.nombre}}
                      <br>
                      <span class="font-weight-bold">Apellido Paterno:</span> {{paciente.apellido_paterno}}
                      <br>
                      <span class="font-weight-bold">Apellido Materno:</span> {{paciente.apellido_materno}}
                      <br>
                      <span class="font-weight-bold">Fecha de Nacimiento:</span> {{paciente.fecha_nacimiento}}
                      <br>
                      <span class="font-weight-bold">Edad:</span> {{paciente.edad}}
                      <br>
                      <span class="font-weight-bold">Sexo:</span> {{paciente.genero ? 'Masculino' : 'Femenino'}}
                    </v-col>
                    <v-col cols="6">
                      <span class="font-weight-bold">Estado:</span> {{paciente.estado}}
                      <br>
                      <span class="font-weight-bold">Municipio:</span> {{paciente.municipio}}
                      <br>
                      <span class="font-weight-bold">Localidad de Atención:</span> {{paciente.localidad_atencion}}
                      <br>
                      <span class="font-weight-bold">CURP:</span> {{paciente.curp || "N/D"}}
                      <br>
                      <span class="font-weight-bold">Poliza Seguro Popular:</span> {{paciente.seguro_popular ? 'Si' : 'No'}}
                      <br>
                      <span class="font-weight-bold">No. Póliza Seguro Popular:</span> {{paciente.num_poliza || "N/D"}}
                      <br>
                      <span class="font-weight-bold">Celular o teléfono de contacto:</span> {{paciente.telefono || "N/D"}}
                      <br>
                      <span class="font-weight-bold">Correo electrónico:</span> {{paciente.email || "N/D"}}
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn
                color="green"
                text
                @click="openDialogUpload"
              >
                <v-icon>
                  mdi-plus
                </v-icon>
                Agregar Imagenes de Mastografía / USG ca mama
              </v-btn>
              <v-dialog
                v-model="dialogUpload"
                max-width="70%"
                persistent
              >
                <v-card>
                  <v-card-title>
                    Agregar Imagenes de Mastografía / USG ca mama
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="6">
                        <v-autocomplete
                          v-model="uploadItem.id_paciente_consulta"
                          label="Consulta"
                          dense
                          hide-details
                          outlined
                          :items="consultas"
                          item-value="id_paciente_consulta"
                        >
                          <template v-slot:selection="data">
                            <span class="font-weight-bold">Fecha y Hora:</span> &nbsp; {{data.item.fecha}}
                          </template>
                          <template v-slot:item="data">
                            <v-list-item-content>                            
                              <v-row class="ma-0 pa-2">
                                <v-col cols="4" class="ma-0 pa-0">
                                  <span class="font-weight-bold">Programa:</span> {{data.item.programa}}
                                </v-col>
                                <v-col cols="4" class="ma-0 pa-0">
                                  <span class="font-weight-bold">Unidad Médica:</span> {{data.item.unidad_medica}}
                                </v-col>
                                <v-col cols="4" class="ma-0 pa-0">
                                  <span class="font-weight-bold">Proyecto:</span> {{data.item.proyecto}}
                                </v-col>
                                <v-col cols="4" class="ma-0 pa-0">
                                  <span class="font-weight-bold">Localidad de Atención:</span> {{data.item.localidad_atencion}}
                                </v-col>
                                <v-col cols="4" class="ma-0 pa-0">
                                  <span class="font-weight-bold">Municipio:</span> {{data.item.municipio}}
                                </v-col>
                                <v-col cols="4" class="ma-0 pa-0">
                                  <span class="font-weight-bold">Estado:</span> {{data.item.estado}}
                                </v-col>
                                <v-col cols="4" class="ma-0 pa-0">
                                  <span class="font-weight-bold">Fecha y Hora:</span> {{data.item.fecha}}
                                </v-col>
                              </v-row>
                            </v-list-item-content>

                          </template>
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="6">
                        <v-autocomplete
                          v-model="uploadItem.id_servicio"
                          label="Servicio"
                          dense
                          hide-details
                          outlined
                          :items="servicios"
                          item-value="id_servicio"
                          item-text="nombre"
                        >
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="12">
                        <v-autocomplete
                          v-model="uploadItem.id_escala_cancer_mama"
                          label="Diagnóstico en Escala BI-RADS"
                          dense
                          hide-details
                          outlined
                          :items="escalaCancerMama"
                          item-value="id_escala_cancer_mama"
                          :item-text="nombreEscala"

                        >
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="12">
                        <v-file-input
                          v-model="uploadItem.interpretacion"
                          label="Interpretación"
                          counter
                          show-size
                          dense
                          hide-details
                          outlined
                          :truncate-length="50"
                          clearable
                          accept=".pdf"
                        ></v-file-input>
                      </v-col>

                      <v-col cols="12">
                        <v-file-input
                          v-model="uploadItem.imagenes"
                          label="Imagenes"
                          counter
                          show-size
                          dense
                          hide-details
                          outlined
                          :truncate-length="50"
                          multiple
                          chips
                          clearable
                          accept=".dcm,.zip"
                        ></v-file-input>
                      </v-col>

                      <v-col
                        v-if="uploading"
                        cols="12"
                        class="text-center"
                      >
                        <v-progress-linear
                          color="primary"
                          height="15"
                          stream
                          :buffer-value="uploadProgress"
                          :value="uploadProgress"
                        >
                          <strong>{{(uploadProgress || 0).toFixed(2)}}%</strong>
                        </v-progress-linear>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="green"
                      @click="closeDialogUpload"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      text
                      color="red"
                      @click="saveDialogUpload"
                    >
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog
                v-model="dialogViewer"
                max-width="80%"
                persistent
              >
                <v-card>
                  <v-card-title>
                    Visualización de Imágenes DICOM
                  </v-card-title>
                  <v-card-text>
                    <v-row v-if="!loadingViewer" class="ma-0 pa-0">
                      <v-col cols="3" class="ma-0 pa-1">
                        <strong>Categoría:</strong> {{(viewerItem.categoria || 'N/D')}}
                        <br>
                        <strong>Descripción:</strong> {{(viewerItem.descripcion|| 'N/D')}}
                        <br>
                        <br>
                        <div
                          v-if="viewerItem.interpretacion != null"
                          style="justify-self: center;"
                        >
                          <v-btn
                            color="primary"
                            @click="nuevaPestana(`/api/Historial/ObtenerInterpretacion/${viewerItem.interpretacion}?jwt=${token}`)"
                          >
                            <v-icon>mdi-download</v-icon> Descargar<br>Interpretación
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col cols="9" class="ma-0 pa-1">
                        <v-carousel>
                          <template v-for="(file, i) in viewerItem.file_identifiers">
                            <v-carousel-item :key="`img-`+i">
                              <!-- Mostrar imagen responsiva -->
                              <v-img
                                :src="nombreImagen(file)"
                                max-height="500px"
                                max-width="100%"
                                contain
                              ></v-img>
                            </v-carousel-item>
                          </template>
                        </v-carousel>
                      </v-col>
                    </v-row>
                    <div v-else class="text-center">
                    <v-progress-circular
                      :size="70"
                      color="primary"
                      indeterminate
                    ></v-progress-circular>
                    <br>
                    <br>
                    <div class="text-h6">Cargando</div>
                  </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="green"
                      @click="closeDicomViewer"
                    >
                      Cerrar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-tabs
                v-model="tab"
                grow
              >
                <v-tab>
                  Información General
                </v-tab>
                <v-tab>
                  Historial de Consultas
                </v-tab>

                <v-tab-item eager>
                  <v-card flat>
                    <v-card-text>
                      <v-row>
                        <v-col cols="3">
                          <v-card>
                            <v-card-text>
                              <div class="text-subtitle-1">Signos Vitales mas Recientes</div>
                              <br>
                              <v-row class="ma-0 pa-0">
                                <v-col cols="2" class="ma-0 pa-0 text-center">
                                  <v-icon
                                    large
                                    color="green darken-2"
                                  >
                                    mdi-weight-kilogram
                                  </v-icon>
                                </v-col>
                                <v-col cols="10" class="text-subtitle-2 ma-0 pa-0 text-left align-self-center">
                                  Peso: {{paciente.signos_vitales.peso}} Kg
                                </v-col>
                                <v-col cols="2" class="ma-0 pa-0 text-center">
                                  <v-icon
                                    large
                                    color="blue darken-2"
                                  >
                                    mdi-human-male-height-variant
                                  </v-icon>
                                </v-col>
                                <v-col cols="10" class="text-subtitle-2 ma-0 pa-0 text-left align-self-center">
                                  Estatura: {{paciente.signos_vitales.estatura}} m
                                </v-col>
                                <v-col cols="2" class="ma-0 pa-0 text-center">
                                  <v-icon
                                    large
                                    color="purple darken-2"
                                  >
                                    mdi-percent-outline
                                  </v-icon>
                                </v-col>
                                <v-col cols="10" class="text-subtitle-2 ma-0 pa-0 text-left align-self-center">
                                  IMC: {{paciente.signos_vitales.imc}}
                                </v-col>
                                <v-col
                                  v-if="paciente.signos_vitales.cintura != null"
                                  cols="2"
                                  class="ma-0 pa-0 text-center"
                                >
                                  <v-icon
                                    large
                                    color="teal darken-2"
                                  >
                                    mdi-tape-measure
                                  </v-icon>
                                </v-col>
                                <v-col
                                  v-if="paciente.signos_vitales.cintura != null"
                                  cols="10"
                                  class="text-subtitle-2 ma-0 pa-0 text-left align-self-center"
                                >
                                  Cintura: {{paciente.signos_vitales.cintura}} cm
                                </v-col>
                                <v-col cols="2" class="ma-0 pa-0 text-center">
                                  <v-icon
                                    large
                                    color="blue-grey darken-2"
                                  >
                                    mdi-thermometer
                                  </v-icon>
                                </v-col>
                                <v-col cols="10" class="text-subtitle-2 ma-0 pa-0 text-left align-self-center">
                                  Temperatura: {{paciente.signos_vitales.temperatura}}° C
                                </v-col>
                                <v-col cols="2" class="ma-0 pa-0 text-center">
                                  <v-icon
                                    large
                                    color="red darken-2"
                                  >
                                    mdi-heart
                                  </v-icon>
                                </v-col>
                                <v-col cols="10" class="text-subtitle-2 ma-0 pa-0 text-left align-self-center">
                                  Pulso: {{paciente.signos_vitales.pulso}} ppm
                                </v-col>
                                <v-col cols="2" class="ma-0 pa-0 text-center">
                                  <v-icon
                                    large
                                    color="orange darken-2"
                                  >
                                    mdi-lungs
                                  </v-icon>
                                </v-col>
                                <v-col cols="10" class="text-subtitle-2 ma-0 pa-0 text-left align-self-center">
                                  Respiración: {{paciente.signos_vitales.respiracion}} rpm
                                </v-col>
                                <v-col cols="2" class="ma-0 pa-0 text-center">
                                  <v-icon
                                    large
                                    color="indigo darken-2"
                                  >
                                    mdi-counter
                                  </v-icon>
                                </v-col>
                                <v-col cols="10" class="text-subtitle-2 ma-0 pa-0 text-left align-self-center">
                                  Oximetría: {{paciente.signos_vitales.oximetria}}% Sp02
                                </v-col>

                                <v-col cols="2" class="ma-0 pa-0 text-center">
                                  <v-icon
                                    large
                                    color="teal darken-2"
                                    v-if="paciente.signos_vitales.antigeno_prostatico != null"
                                  >
                                    mdi-clipboard-account-outline
                                  </v-icon>
                                </v-col>
                                <v-col
                                  v-if="paciente.signos_vitales.antigeno_prostatico != null"
                                  cols="10"
                                  class="text-subtitle-2 ma-0 pa-0 text-left align-self-center"
                                >
                                  Antigeno Prostatico: {{paciente.signos_vitales.antigeno_prostatico}}% Sp02
                                </v-col>

                                <v-col cols="2" class="ma-0 pa-0 text-center">
                                  <v-icon
                                    large
                                    color="green darken-2"
                                    v-if="paciente.signos_vitales.otros != null"
                                  >
                                    mdi-comment-text
                                  </v-icon>
                                </v-col>
                                <v-col
                                  v-if="paciente.signos_vitales.otros != null"
                                  cols="10"
                                  class="text-subtitle-2 ma-0 pa-0 text-left align-self-center"
                                >
                                  Observaciones: {{paciente.signos_vitales.otros}}
                                </v-col>

                                <v-col
                                  v-if="paciente.signos_vitales.sistolica_izquierda != null || paciente.signos_vitales.sistolica_derecha != null"
                                  cols="2"
                                  class="ma-0 pa-0 text-center align-self-center"
                                >
                                  <v-icon
                                    large
                                    color="blue darken-2"
                                  >
                                    mdi-heart-pulse
                                  </v-icon>
                                </v-col>
                                <v-col
                                  v-if="paciente.signos_vitales.sistolica_izquierda != null || paciente.signos_vitales.sistolica_derecha != null || paciente.signos_vitales.hipertension_arterial_otros != null"
                                  cols="10"
                                  class="text-subtitle-2 ma-0 pa-0 text-left align-self-center"
                                >
                                  <template
                                    v-if="paciente.signos_vitales.sistolica_izquierda != null"
                                  >
                                    T.A. Sistólica izq: {{paciente.signos_vitales.sistolica_izquierda}}
                                    <br>
                                  </template>
                                  <template
                                    v-if="paciente.signos_vitales.diastolostica_izquierda != null"
                                  >
                                    T.A. Diastólica izq: {{paciente.signos_vitales.diastolostica_izquierda}}
                                    <br>
                                  </template>

                                  <template
                                    v-if="paciente.signos_vitales.sistolica_derecha != null"
                                  >
                                    T.A. Sistólica der: {{paciente.signos_vitales.sistolica_derecha}}
                                    <br>
                                  </template>
                                  <template
                                    v-if="paciente.signos_vitales.diastolostica_derecha != null"
                                  >
                                    T.A. Diastólica der: {{paciente.signos_vitales.diastolostica_derecha}}
                                    <br>
                                  </template>
                                  <template
                                    v-if="paciente.signos_vitales.hipertension_arterial_otros != null"
                                  >
                                    Observaciones: {{paciente.signos_vitales.hipertension_arterial_otros}}
                                    <br>
                                  </template>
                                  <br>
                                </v-col>

                                <v-col cols="2" class="ma-0 pa-0 text-center align-self-center">
                                  <v-icon
                                    large
                                    color="purple darken-2"
                                    v-if="paciente.signos_vitales.glucosa != null || (paciente.signos_vitales.colesterol != null || paciente.signos_vitales.colesterol_otro != null) || (paciente.signos_vitales.trigliceridos != null || paciente.signos_vitales.trigliceridos_otro != null)"
                                  >
                                    mdi-flask
                                  </v-icon>
                                </v-col>
                                <v-col
                                  v-if="paciente.signos_vitales.glucosa != null || (paciente.signos_vitales.colesterol != null || paciente.signos_vitales.colesterol_otro != null) || (paciente.signos_vitales.trigliceridos != null || paciente.signos_vitales.trigliceridos_otro != null)"
                                  cols="10"
                                  class="text-subtitle-2 ma-0 pa-0 text-left align-self-center"
                                >
                                  <template v-if="paciente.signos_vitales.glucosa != null">
                                    Glucosa: {{paciente.signos_vitales.glucosa}}
                                    <br>
                                  </template>
                                  <template v-if="(paciente.signos_vitales.colesterol != null || paciente.signos_vitales.colesterol_otro != null)">
                                    Colesterol: {{paciente.signos_vitales.colesterol_otro || paciente.signos_vitales.colesterol}}
                                    <br>
                                  </template>
                                  <template v-if="(paciente.signos_vitales.trigliceridos != null || paciente.signos_vitales.trigliceridos_otro != null)">
                                    Trigliceridos: {{paciente.signos_vitales.trigliceridos_otro || paciente.signos_vitales.trigliceridos}}
                                    <br>
                                  </template>
                                  <template v-if="paciente.signos_vitales.quimicas_secas_observaciones != null">
                                    Observaciones: {{paciente.signos_vitales.quimicas_secas_observaciones}}
                                  </template>
                                </v-col>
                              </v-row>
                              <br>
                              <div class="text-body-2">Fecha de Mediciones: {{paciente.signos_vitales.fecha}}</div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col cols="9">
                          <v-card>
                            <v-card-text>
                              <div class="text-subtitle-1">Revisiones Medicas</div>
                              <br>
                              <v-data-table
                                :items="paciente.consultas"
                                :headers="paciente.servicios"
                                no-data-text="No hay Revisiones Medicas Anteriores"
                                hide-default-footer
                              >
                                <template v-for="header in paciente.servicios" v-slot:[`item.${header.value}`]="{ item }">
                                  <!-- Contenido personalizado para cada columna -->
                                  <template v-if="typeof item[header.value] == 'string'">
                                    <span
                                      :key="'col-'+header.value"
                                    >
                                      {{ item[header.value] }}
                                    </span>
                                  </template>
                                  <template v-else>
                                    <template
                                      v-if="item[header.value] == true"
                                    >
                                      <v-icon
                                        v-if="header.id_servicio == '97d17f26-9a0e-4dcf-9bf1-994de1fe7546' || header.id_servicio == '6725973a-9fb2-4210-be4f-7b3727886e31'"
                                        :key="'col-'+header.value"
                                        color="green"
                                        @click="openDicomViewer(item.id_paciente_servicio)"
                                      >
                                        mdi-check
                                      </v-icon>
                                      <v-icon
                                        v-else
                                        :key="'col-'+header.value"
                                        color="green"
                                      >
                                        mdi-check
                                      </v-icon>

                                    </template>

                                    <v-icon
                                      v-if="item[header.value] == false"
                                      :key="'col-'+header.value"
                                      color="red"
                                    >
                                      mdi-clock-alert-outline
                                    </v-icon>
                                  </template>
                                </template>
                              </v-data-table>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>

                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item eager>
                  <v-card flat>
                    <v-card-text v-if="!paciente.historico">
                      <historial
                        ref="historial"
                      >
                      </historial>
                    </v-card-text>
                    <v-card-text v-else>
                      <div class="text-center">
                        <v-icon
                          size="100"
                          color="yellow"
                        >
                          mdi-alert
                        </v-icon>
                      </div>
                      Este paciente se considera histórico debido a que sus registros se realizaron antes de la implementación del sistema actual. Como resultado, su historial de consultas no está disponible en esta plataforma.
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            text
            @click="closeDialog"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import Historial from '@/components/DashViews/Comunes/Historial.vue'
import VAlertDialog from '@/components/VAlertDialog.vue'

export default {
  components: {
    Historial,
    VAlertDialog
  },
  data: () => ({
    tab: 0,
    dialogExpediente: false,
    id_paciente: null,
    paciente: {
      id_paciente: null,
      id_municipio: null,
      municipio: "",
      id_estado: null,
      estado: "",
      id_localidad_atencion: null,
      localidad_atencion: "",
      folio: "",
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      fecha_nacimiento: "",
      edad: "",
      genero: false,
      curp: null,
      seguro_popular: false,
      num_poliza: null,
      telefono: null,
      email: null,
      servicios: [],
      consultas: [],
      historico: false,
      signos_vitales: {
        peso: null,
        estatura: null,
        imc: null,
        cintura: null,
        temperatura: null,
        pulso: null,
        respiracion: null,
        oximetria: null,
        antigeno_prostatico: null,
        otros: null,
        sistolica_izquierda: null,
        diastolostica_izquierda: null,
        sistolica_derecha: null,
        diastolostica_derecha: null,
        hipertension_arterial_otros: null,
        glucosa: null,
        colesterol: null,
        colesterol_otro: null,
        trigliceridos: null,
        trigliceridos_otro: null,
        quimicas_secas_observaciones: null,
        fecha: null
      }
    },
    defaultPaciente: {
      id_paciente: null,
      id_municipio: null,
      municipio: "",
      id_estado: null,
      estado: "",
      id_localidad_atencion: null,
      localidad_atencion: "",
      folio: "",
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      fecha_nacimiento: "",
      edad: "",
      genero: false,
      curp: null,
      seguro_popular: false,
      num_poliza: null,
      telefono: null,
      email: null,
      servicios: [],
      consultas: [],
      historico: false,
      signos_vitales: {
        peso: null,
        estatura: null,
        imc: null,
        cintura: null,
        temperatura: null,
        pulso: null,
        respiracion: null,
        oximetria: null,
        antigeno_prostatico: null,
        otros: null,
        sistolica_izquierda: null,
        diastolostica_izquierda: null,
        sistolica_derecha: null,
        diastolostica_derecha: null,
        hipertension_arterial_otros: null,
        glucosa: null,
        colesterol: null,
        colesterol_otro: null,
        trigliceridos: null,
        trigliceridos_otro: null,
        quimicas_secas_observaciones: null,
        fecha: null
      }
    },
    dialogUpload: false,
    loadingEscalaCancerMamas: false,
    escalaCancerMama: [],
    uploadItem:{
      id_paciente_consulta: null,
      id_servicio: null,
      id_escala_cancer_mama: null,
      imagenes: [],
      interpretacion: null
    },
    defaultUploadItem:{
      id_paciente_consulta: null,
      id_servicio: null,
      id_escala_cancer_mama: null,
      imagenes: [],
      interpretacion: null
    },
    servicios: [
      { id_servicio: '97d17f26-9a0e-4dcf-9bf1-994de1fe7546', nombre: 'Mastografía'},
      { id_servicio: '6725973a-9fb2-4210-be4f-7b3727886e31', nombre: 'USG ca mama'}
    ],
    consultas: [],
    loadingConsultas: false,
    uploadProgress: 0,
    uploading: false,

    loadingViewer: false,
    dialogViewer: false,
    viewerItem: {
      categoria: null,
      descripcion: null,
      interpretacion: null,
      id_escala_cancer_mama: null,
      file_identifiers: [],
    },
    defaultViewerItem: {
      categoria: null,
      descripcion: null,
      interpretacion: null,
      id_escala_cancer_mama: null,
      file_identifiers: [],
    },
  }),
  computed: {
    ...mapState(['token', 'storage_url'])
  },
  mounted(){
    this.getEscalaCancerMamas();
  },
  methods: {
    nombreEscala: escala => `[${escala.categoria}] - ${escala.descripcion}`,
    getInformation(){
      this.paciente = JSON.parse(JSON.stringify(this.defaultPaciente));
      this.consultas = [];

      this.loading = true;
      axios
      .get(`/Pacientes/Expediente/${this.id_paciente}`)
      .then(response => {
        setTimeout(() => {
          this.$refs.historial.cambiarPaciente(this.id_paciente);
        },500);
        this.paciente = response.data;
        this.paciente.servicios.unshift({
          text: "Fecha",
          value: "fecha",
          align: "center",
        });
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      })
    },
    openDialog(id_paciente){
      this.dialogExpediente = true;
      this.id_paciente = id_paciente;
      this.getInformation();
    },
    closeDialog(){
      try {
        this.$refs.historial.limpiarHistorial();
      } catch (error) { 
          console.log(error);
      }

      this.dialogExpediente = false;
      this.tab = 0;
      this.paciente = JSON.parse(JSON.stringify(this.defaultPaciente));
      this.id_paciente = null;
    },
    openDialogUpload(){
      this.dialogUpload = true;
      this.getConsultas(this.id_paciente);
    },
    closeDialogUpload(){
      this.dialogUpload = false;
      this.uploadItem = Object.assign({}, this.defaultUploadItem);
      this.consultas = [];
      this.uploadProgress = 0;
    },
    saveDialogUpload() {
      if (this.uploadItem.id_paciente_consulta == null) {
        this.$refs.alert.setMessage('', 'Debe seleccionar una consulta');
        this.$refs.alert.open();
        return;
      }
      
      if (this.uploadItem.id_servicio == null) {
        this.$refs.alert.setMessage('', 'Debe seleccionar un servicio');
        this.$refs.alert.open();
        return;
      }
      
      if (this.uploadItem.id_escala_cancer_mama == null) {
        this.$refs.alert.setMessage('', 'Debe seleccionar un diagnóstico en escala BI-RADS');
        this.$refs.alert.open();
        return;
      }
      
      if (!this.uploadItem.imagenes || this.uploadItem.imagenes.length === 0) {
        this.$refs.alert.setMessage('', 'Debe adjuntar al menos una imagen en formato .dcm o .zip');
        this.$refs.alert.open();
        return;
      }
      
      if (!this.uploadItem.interpretacion) {
        this.$refs.alert.setMessage('', 'Debe adjuntar un archivo de interpretación en formato PDF');
        this.$refs.alert.open();
        return;
      }

      const formData = new FormData();
      formData.append("id_paciente_consulta", this.uploadItem.id_paciente_consulta);
      formData.append("id_servicio", this.uploadItem.id_servicio);
      formData.append("id_escala_cancer_mama", this.uploadItem.id_escala_cancer_mama);

      this.uploadItem.imagenes.forEach((file) => {
        formData.append("imagenes", file);
      });

      formData.append("interpretacion", this.uploadItem.interpretacion);

      this.uploading = true;

      axios.post(`${this.storage_url}/HistoricalUpload/UploadHistoricalDicom`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: function( progressEvent ) {
          this.uploadProgress = ((progressEvent.loaded / progressEvent.total) * 100.0);
        }.bind(this)
      })
      .then(() => {
        let id_paciente = this.id_paciente;
        this.closeDialogUpload();
        this.closeDialog();
        
        this.openDialog(id_paciente);
        
      })
      .catch((error) => {
        console.error("Error al enviar los datos", error);
        this.$refs.alert.setMessage('Error', 'Hubo un problema al enviar los datos. Inténtelo nuevamente.');
        this.$refs.alert.open();
      })
      .finally(() => {
        this.uploadProgress = 0;
        this.uploading = false;
      });
    },
    getEscalaCancerMamas(){
      this.loadingEscalaCancerMamas = true;
      axios
      .get('/Catalogos/ListarEscalaCancerMamas')
      .then(response => {
        this.escalaCancerMama = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingEscalaCancerMamas = false;
      })
    },
    getConsultas(id_paciente){
      this.loadingConsultas = true;

      axios
      .get(`/Historial/ListarHistorial/${id_paciente}`)
      .then(response => {
        this.consultas = response.data;
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.loadingConsultas = false;
      })
    },
    openDicomViewer(id_paciente_servicio){
      this.dialogViewer = true;

      this.loadingViewer = true;

      axios
      .get(`/Historial/ListarImagenesDicom/${id_paciente_servicio}`)
      .then(response => {
        this.viewerItem = response.data;
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.loadingViewer = false;
      })
    },
    closeDicomViewer(){
      this.dialogViewer = false;
      this.loadingViewer = false;
      this.viewerItem = Object.assign({}, this.defaultViewerItem);
    },
    nuevaPestana(url) {
      window.open(url, '_blank');
    },
    nombreImagen(file){
      return `${this.storage_url}/Dicom/GetThumnail/${file}?jwt=${this.token}`; 
    },
  }
}
</script>