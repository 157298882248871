<template>
  <v-row class="ma-0 pa-0">
    <v-col cols="12" class="pa-0 ma-0">
      <v-tabs
        v-model="tab"
        centered
        grow
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab
          v-if="mostrarVista(11)"
          href="#movimientos"
          @click="actualizarItems('movimientos')"
        >
          Movimientos
        </v-tab>

        <v-tab
          v-if="mostrarVista(25)"
           href="#incidencias"
          @click="actualizarItems('incidencias')"
        >
          Resumen de Movimientos de Almacén
        </v-tab>

        <!--
        <v-tab
          href="#resumen"
          @click="actualizarItems('resumen')"
        >
          Resumen de Movimientos
        </v-tab>
        -->

        <v-tab
          v-if="mostrarVista(26)"
          href="#dispensacion"
          @click="actualizarItems('dispensacion')"
        >
          Dispensacion
        </v-tab>

        <v-tab
          v-if="mostrarVista(27)"
          href="#farmacia"
          @click="actualizarItems('farmacia')"
        >
          Farmacias UMM
        </v-tab>

        <!--
        <v-tab
          href="#rechazo"
          @click="actualizarItems('rechazo')"
        >
          Motivos de Rechazo
        </v-tab>
        -->

        <v-tab
          v-if="mostrarVista(28)"
          href="#tipos"
          @click="actualizarItems('tipos')"
        >
          Tipos de Movimientos
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
          v-if="mostrarVista(11)"
          value="movimientos"
        >
          <Movimientos
            ref="movimientos"
          ></Movimientos>
        </v-tab-item>

        
        <v-tab-item
          v-if="mostrarVista(25)"
          value="incidencias"
        >
          <incidencias
            ref="incidencias"
          ></incidencias>
        </v-tab-item>

        <!--
        <v-tab-item value="resumen">
          <Resumen
            ref="resumen"
          ></Resumen>
        </v-tab-item>
        -->
        <v-tab-item
          v-if="mostrarVista(27)"
          value="farmacia"
        >
          <Almacen
            ref="farmacia"
          ></Almacen>
        </v-tab-item>
        <!--
        <v-tab-item value="rechazo">
          <MotivoRechazo
            ref="rechazo"
          ></MotivoRechazo>
        </v-tab-item>
        -->
        <v-tab-item
          v-if="mostrarVista(28)"
          value="tipos"
        >
          <TipoMovimiento
            ref="tipos"
          ></TipoMovimiento>
        </v-tab-item>

        <v-tab-item
          v-if="mostrarVista(26)"
          value="dispensacion"
        >
          <Farmacia
            ref="dispensacion"
          ></Farmacia>
        </v-tab-item>
      </v-tabs-items>
      </v-col>
    </v-row>
</template>

<script>
import Movimientos from './Almacen/Movimientos.vue'
//import Resumen from './Almacen/Resumen.vue'
import Almacen from './Almacen/Almacen.vue'
//import MotivoRechazo from './Almacen/MotivoRechazo.vue'
import TipoMovimiento from './Almacen/TipoMovimiento.vue'
import Farmacia from './Almacen/Farmacia.vue'
import Incidencias from './Incidencias/Farmacia.vue'
import { mapState } from 'vuex'

export default {
  components:{
    Movimientos,
    //Resumen,
    Almacen,
    //MotivoRechazo,
    TipoMovimiento,
    Farmacia,
    Incidencias
  },
  computed: {
    ...mapState(['ids_vistas'])
  },
  data: () => ({
    tab: 'movimientos'
  }),
  mounted() {
  },
  methods: {
    mostrarVista(id_vista){
      if(this.ids_vistas == null || this.ids_vistas.length == 0) return false;
      return (this.ids_vistas.indexOf(id_vista) >= 0);
    },
    getItems(){
      this.actualizarItems(this.tab);
    },
    actualizarItems(refName) {
      if (this.$refs[refName] && this.$refs[refName].getItems) {
        this.$refs[refName].getItems();
      } else {
        console.warn(`No se encontró la referencia: ${refName}`);
      }
    }
  }
}
</script>